import { FC } from 'react';
import IconProps from './IconProps';

const EyeIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8281 11.6023C14.8281 13.165 13.5612 14.4318 11.9985 14.4318C10.4358 14.4318 9.16895 13.165 9.16895 11.6023C9.16895 10.0395 10.4358 8.77274 11.9985 8.77274C13.5612 8.77274 14.8281 10.0395 14.8281 11.6023Z"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11.6023C4.20187 7.77566 7.77679 5 12 5C16.2232 5 19.7981 7.77569 21 11.6023C19.7981 15.4288 16.2232 18.2045 12 18.2045C7.77678 18.2045 4.20185 15.4288 3 11.6023Z"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIcon;
